<template>
    <div ref='current'>
        <el-autocomplete
                :maxlength='8'
                size='12'
                valueKey='plateNumber'
                class="inline-input"
                v-model.trim="plateNumber"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
                style='width:180px'
        ></el-autocomplete>
    </div>
</template>
<script>
export default {
  data () {
      return {
          plateNumber: ''
      }
  },
  props: {
  },
  watch: {
      plateNumber (newVal) {
          if (!newVal) {
              this.$emit('valueChange', '');
          }
      }
  },
  methods: {
      querySearchAsync (queryString, cb) {
         queryString = queryString.replace(/\s/g, '')
         if (queryString.length < 3) {
           cb([]);
           return
         }
        this.$axios.get('/acb/2.0/parkRecord/plateNumber/list', {
          data: {
            part: queryString,
            size: 100
          }
        }).then(res => {
          if (res.state == 0) {
             cb(res.value);
          }
        })
      },
       setShowVal (val) {
            this.plateNumber = val;
        },
        setValue () {
            this.plateNumber = '';
            this.$emit('valueChange', '');
        },
      handleSelect (item) {
          this.$emit('valueChange', item.carId);
      }
  },
  mounted () {
    }
}
</script>

