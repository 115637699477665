<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('searchModule.plate_number')">
            <platenumber-con
              @valueChange="
                (v) => {
                  formInline.carId = v;
                }
              "
            ></platenumber-con>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')">
            <el-input v-model.trim="formInline.phoneNumber" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input
              v-model.trim="formInline.parkCardTitle"
              maxlength="10"
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="适用范围">
            <el-select v-model.trim="formInline.scopeType"
                       placeholder="请选择">
              <el-option label="请选择"
                         value="0"></el-option>
              <el-option label="全部车场"
                         value="1"></el-option>
              <el-option label="部分车场"
                         value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="formInline.scopeType == 2">
            <auto-complete ref="select"
                           @dataChange="v => (temParkData = v)"
                           @valueChange="completeValue"
                           slaveRelations="0,1"></auto-complete>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.Purchase_channels')">
            <el-select v-model.trim="formInline.buyType" filterable placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code + ''"
                :key="value.code"
                v-for="value in payTypeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Order_status')">
            <el-select v-model.trim="formInline.state" filterable placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code + ''"
                :key="value.code"
                v-for="value in statusList"
              ></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item :label="$t('searchModule.Payment_devices')">
            <el-select v-model.trim="formInline.devType"
                       filterable
                       placeholder="请选择">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="value.desc"
                         :value="value.code + ''"
                         :key="value.code"
                         v-for="value in exqList"></el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item :label="$t('searchModule.Payment_channels')" prop="channelType">
            <el-select v-model.trim="formInline.channelType">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.channelTypeDesc"
                :value="value.channelType"
                :key="value.channelType"
                v-for="value in channelList"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Payment_devices')" prop="devType">
            <el-select v-model.trim="formInline.devType">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in plantList"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Payment_Time')">
            <el-date-picker
              v-model="datetimerange"
              type="datetimerange"
              range-separator="至"
              :editable="false"
              :clearable="false"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
            <!-- v-if="$route.meta.authority.button.add" -->
            <!-- <el-button type="primary"
                       icon="el-icon-plus"

                       @click="$router.push('/financialParkCardAddEdit')"
                       :loading="loading">添加订单
            </el-button> -->
            <el-button
              type="primary"
              plain
              icon="el-icon-upload2"
              v-if="$route.meta.authority.button.export"
              @click="exportFile"
              >导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            :show-overflow-tooltip="item.prop == 'useRangeDesc'"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <!-- <el-table-column label="操作"
                           align="center"
                           width="80">
            <template slot-scope="scope"
                      v-if="$route.meta.authority.button.edit || $route.meta.authority.button.view">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="primary"
                           size="small">操作</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="b"
                                    v-if="$route.meta.authority.button.edit && scope.row.state == 1">修改
                  </el-dropdown-item>
                  <el-dropdown-item command="a"
                                    v-if="$route.meta.authority.button.view">查看</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper" v-show="total > 0">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import autoComplete from "@/components/autocomplete";
import platenumberCon from "@/components/plateNumber";
import { exportExcelNew, dateFormat } from "@/common/js/public";

export default {
  name: "parkCardPayOrder",
  data() {
    const start = new Date();
    const end = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    return {
      modelvalue: "",
      useRange: "",
      stateList: [],
      channelList: [],
      plantList: [],
      temParkData: {},
      datetimerange: [
        dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
        dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      ],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "plateColor",
          label: this.$t("list.License_plate_color"),
          width: "",
        },
        {
          prop: "phoneNumber",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "parkCardTitle",
          label: "商品名称",
          width: "",
        },
        // {
        //   prop: "useRangeDesc",
        //   label: "适用范围",
        //   width: ""
        // },
        // {
        //   prop: "effectStartDate",
        //   label: "生效开始日期",
        //   width: "113"
        // },
        // {
        //   prop: "validityPeriod",
        //   label: "有效天数",
        //   width: ""
        // },
        {
          prop: "actualAmount",
          label: this.$t("list.Pay_amount"),
          width: "",
          formatter: (r, c, v) => {
            return v ? v / 100 : 0;
          },
        },
        //        {
        //          prop: 'payTypeDesc',
        //          label: '支付方式'
        //        },
        {
          prop: "channelTypeDesc",
          label: this.$t("list.Payment_channels"),
        },
        {
          prop: "devTypeDesc",
          label: this.$t("list.Payment_devices"),
        },
        {
          prop: "buyTypeDesc",
          label: "购买渠道",
          width: "",
        },
        {
          prop: "createTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
        {
          prop: "stateDesc",
          label: "订单状态",
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        parkCardRuleId: "",
        scopeType: "0",
        parkId: "",
        phoneNumber: "",
        carId: "",
        parkCardTitle: "",
        buyType: "",
        scope: "",
        state: "",
        createTimeStart: "",
        createTimeEnd: "",
        devType: "",
        channelType: "",
      },
      payTypeList: [],
      scopeList: [],
      statusList: [],
      exqList: [],
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    // 获取渠道列表
    getChannelList() {
      this.$axios.get("/acb/2.0/payOrder/payChannelTypeDictList").then((res) => {
        if (res.state == 0) {
          this.channelList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取设备列表
    getPlantList() {
      this.$axios.get("/acb/2.0/payOrder/devTypeList").then((res) => {
        if (res.state == 0) {
          this.plantList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            areaIds: this.streetId ? this.streetId : this.areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getDownMenu() {
      this.$axios.get("/acb/2.0/parkCard/getSelectionValues").then((res) => {
        this.payTypeList = res.value.buyType;
        this.scopeList = res.value.scope;
        this.statusList = res.value.status;
      });
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({ path: "/financialParkCardDetail", query: data });
      }
      if (cmd == "b") {
        this.$router.push({ path: "/financialParkCardDetail", query: { ...data, isEdit: true } });
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportFile() {
      exportExcelNew("/acb/2.0/parkCard/export", {
        page: this.page,
        pageSize: this.pageSize,
        ...this.formInline,
      });
    },
    // 搜索
    searchData() {
      if (this.formInline.scopeType == 1) {
        this.formInline.parkId = "";
      }
      if (this.datetimerange) {
        this.formInline.createTimeStart = this.datetimerange[0];
        this.formInline.createTimeEnd = this.datetimerange[1];
      } else {
        this.loading = false;
        return this.$alert("请选择办理时间");
      }

      let time = new Date(this.datetimerange[1]) - new Date(this.datetimerange[0]);
      time = time / (24 * 60 * 60 * 1000);
      if (time >= 31) {
        this.$alert("查询时间不能大于31天");
        return false;
      }
      this.tableData = [];
      this.loading = true;
      let url = "/acb/2.0/parkCard/list";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((er) => {
          this.total = 0;
          this.loading = false;
        });
    },
    getExq(data) {
      this.$axios
        .get("/acb/2.0/payOrder/devTypeList")
        .then((res) => {
          // console.log("测试", res)
          this.exqList = res.value;
        })
        .catch(() => {
          this.exqList = [];
        });
    },
  },
  activated() {
    this.searchData();
  },
  components: {
    // autoComplete,
    platenumberCon,
  },
  created() {
    // console.log("$route.meta.authority.", this.$route.meta.authority);
    this.getDownMenu();
    this.getExq();
  },
  computed: {},
  mounted() {
    this.getChannelList();
    this.getPlantList();
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
